[aria-hidden=true] {
  max-height: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

[aria-hidden=false],
.is-open,
.is-active {
  max-height: 999px;
  overflow: hidden;
  visibility: visible;
  transition: all ease-in-out 0.3s;
}

/**
 * Generate colour variables using the colours object/map located
 * in the abstracts/colour.scss file.
 *
 * Loop over all colour items inside the 'colours' object/map 
 * and for each of those, loop over the shades (100 - 900)
 * for each and interpolate the values as CSS variables
 *
 * Also create a seperate utilty colour variable which can handle
 * styles without any shading e.g. white these can be added or 
 * modified in the $utilityColour object in 'colours' scss
 * 
 */
:root {
  --clr-white: #ffffff;
  --clr-black-100: #f2f2f2;
  --clr-black-200: #d9d9d9;
  --clr-black-300: #bfbfbf;
  --clr-black-400: #a6a6a6;
  --clr-black-500: #8c8c8c;
  --clr-black-600: #737373;
  --clr-black-700: #595959;
  --clr-black-800: #2e2e2e;
  --clr-black-900: #121212;
  --clr-red-100: #fee6e8;
  --clr-red-200: #fd9ba2;
  --clr-red-300: #fb505b;
  --clr-red-400: #fa1e2d;
  --clr-red-500: #e10513;
  --clr-red-600: #af040f;
  --clr-red-700: #7d030b;
  --clr-red-800: #4b0206;
  --clr-red-900: #150f0f;
  --clr-blue-100: #c7edff;
  --clr-blue-200: #94ddff;
  --clr-blue-300: #61cdff;
  --clr-blue-400: #2ebdff;
  --clr-blue-500: #00abfa;
  --clr-blue-600: #0088c7;
  --clr-blue-700: #006594;
  --clr-blue-800: #004261;
  --clr-blue-900: #001f2e;
  --clr-green-100: #def5d1;
  --clr-green-200: #ccefb8;
  --clr-green-300: #a2e37d;
  --clr-green-400: #85d954;
  --clr-green-500: #68cd2d;
  --clr-green-600: #52a324;
  --clr-green-700: #3d791b;
  --clr-green-800: #284f11;
  --clr-green-900: #132608;
  --clr-yellow-100: #fef9e6;
  --clr-yellow-200: #fdeaa5;
  --clr-yellow-300: #fbde74;
  --clr-yellow-400: #fad242;
  --clr-yellow-500: #f9c610;
  --clr-yellow-600: #d1a505;
  --clr-yellow-700: #9f7e04;
  --clr-yellow-800: #634e03;
  --clr-yellow-900: #1e1a0a;
}

/* Typography
/* -------------------- */
html, body {
  font-family: "Inter", sans-serif;
}

.flex, .d-flex {
  display: flex;
}